import SmartAgent from "@missionlabs/smartagent-app-components/dist/smartagent";
import axios from "axios";
import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { IMulesoftCustomerAccount, IMulesoftCustomerBalance, IMulesoftPersonDetails, IPartialContact, PopupMessageType } from "types";

interface ISmartagentContext {
    transferToPayment(useDTMF: boolean): void
    updateContractDetailsPlugin(
        data: {
                  personDetails: IMulesoftPersonDetails | undefined
                  account: IMulesoftCustomerAccount | undefined
              } | undefined
    ): void
    updateBillingPlugin(data: IMulesoftCustomerBalance | undefined): void
    endConferenceCall(useDTMF: boolean): void
    showPopupMessage(type: PopupMessageType, message: string): void
    contact: IPartialContact
    resetPlugins(): void
    acqueonContactNumber: string | undefined
    billAccountNumber: string | undefined
}

export const SmartagentContext = createContext<ISmartagentContext>({} as any);

export const SmartagentProvider: React.FC = ({ children }) => {

    const smartagent = useRef(new SmartAgent()).current;
    const [contact, setContact] = useState<IPartialContact>({ ID: "", originalContactID: '' });
    const [acqueonContactNumber, setAcqueonContactNumber] = useState<string | undefined>();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [billAccountNumber, setBillAccountNumber] = useState<string | undefined>();

    const getConnectionName = useCallback((useDTMF: boolean) => {
        return useDTMF ? 'DTMF Payment - Card' : 'Voice Payment - Card'
    }, []);

    useEffect(() => {
        const interceptor = axios.interceptors.request.use((config) => {
            if (token) {
                config.headers = {
                    ...config.headers,
                    'X-Amz-Security-Token': token,
                };
            }
            return config;
        });

        return () => axios.interceptors.request.eject(interceptor);

    }, [token]);

    useEffect(() => {
        smartagent.init().then((initialContact: IPartialContact) => {
            console.log('thames-pci: got contact- ', initialContact);
            smartagent.getToken()
                .then(setToken)
                .then(() => setContact({ ...initialContact, ID: initialContact.originalContactID ?? initialContact.ID }))
        });

        // refresh token
        setInterval(() => {
            smartagent.getToken().then(setToken)
        }, 60_000)

        smartagent.sendMessage('update_quick_connects');
    }, [smartagent])

    useEffect(() => {
        const cb = (newContact: IPartialContact) => {
            console.log('thames-pci: got new contact', newContact, 'old: ', contact);
            setBillAccountNumber(newContact.attributes?.['sa-tw-pci-bill-account']);

            if (newContact.ID !== contact.ID) {
                setContact({ ...newContact, ID: newContact.originalContactID ?? newContact.ID })
            }
        }
        smartagent.on('contact_updated', cb);

        const acqueonContactNumberUpdated = (message: { source: string; accountNumber: string }) => {
            console.log('thames-pci: got new acqueon contact number', message.accountNumber);
            if (message.source === 'tw-acqueon-plugin') {
                setAcqueonContactNumber(message.accountNumber)
            }
        }
        smartagent.on('acqueon_contact_updated', acqueonContactNumberUpdated)

        return () => {
            smartagent.off('contact_updated', cb);
            smartagent.off('acqueon_contact_updated', acqueonContactNumberUpdated);
        }
    }, [smartagent, contact]);


    const transferToPayment: ISmartagentContext["transferToPayment"] = useCallback((useDTMF) => {
        smartagent.sendMessage('quick_connect', {
            name: getConnectionName(useDTMF)
        })

    }, [smartagent, getConnectionName]);

    const updateContractDetailsPlugin = useCallback(
        (data: {
            personDetails: IMulesoftPersonDetails,
            account: IMulesoftCustomerAccount
        } | undefined) => {
            smartagent.updateContact({
                'sa-thames-customer-contract-details': data
                    ? JSON.stringify(data)
                    : undefined
            })
        },
        [smartagent]
    )

    const updateBillingPlugin = useCallback(
        (data: IMulesoftCustomerBalance | undefined) => {
            smartagent.updateContact({
                'sa-thames-customer-billing': data
                    ? JSON.stringify(data)
                    : undefined
            })
        },
        [smartagent]
    )

    const resetPlugins = useCallback(() => {
        updateContractDetailsPlugin(undefined)
        updateBillingPlugin(undefined)
    }, [updateBillingPlugin, updateContractDetailsPlugin]);

    const endConferenceCall = useCallback((useDTMF) => {
        const connectionName = getConnectionName(useDTMF)
        smartagent.sendMessage('end_conference_call', {
            connectionName
        });
    }, [smartagent, getConnectionName]);

    const showPopupMessage = useCallback(
        (type, message) => {
            smartagent.sendMessage('show_popup_message', {
                type,
                message
            })
        },
        [smartagent]
    )

    return (
        <SmartagentContext.Provider value={{
            transferToPayment,
            updateContractDetailsPlugin,
            updateBillingPlugin,
            contact,
            endConferenceCall,
            resetPlugins,
            showPopupMessage,
            acqueonContactNumber,
            billAccountNumber
        }}>
            {children}
        </SmartagentContext.Provider>
    )
}
