import Main from 'views/Main';
import React from 'react';
import { AppProvider } from 'contexts/AppContext';
import { Box, PluginHarness } from '@missionlabs/smartagent-app-components';
import { SmartagentProvider } from 'contexts/SmartagentContext';

const devContact = {
    ID: '010701ac-e99e-4f78-8d58-3027a68b5b0e'
};

const App: React.FC<unknown> = () => {
    const isEmbedded = window.parent !== window;

    return (
        <SmartagentProvider>
            <AppProvider>
                {
                    isEmbedded ? (
                        <Main />
                    ) : (
                        <PluginHarness contact={devContact}>
                            <Box alt header="Thames Water Payment" collapse>
                                <Main />
                            </Box>
                        </PluginHarness>
                    )
                }
            </AppProvider>
        </SmartagentProvider>
    );
};

export default App;
