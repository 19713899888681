import { useSmartagent } from "hooks/useSmartagent"
import { useCallback, useEffect, useMemo, useState } from "react";
import * as API from 'services/api'
import { IMulesoftCustomer, MulesoftTicketId } from "types";
import { PaymentStatusState } from './usePaymentStatus'
import { ICustomersDetails } from 'types';
import { isValidUkMobileNumber, formatToE164Number } from '../../utils'

const blankCustomerDetails: ICustomersDetails = {
    type: "Residential",
    accountId: '',
    customerId: '',
    customerReference: '',
    paymentAmount: '',
    customerName: '',
    tokenId: undefined,
}

export const useCustomerDetails = (accountId: string, paymentStatus: PaymentStatusState, updateAccountId:(accountId: string)=> void, mulesoftTicketId: MulesoftTicketId ) => {
    const { updateBillingPlugin, updateContractDetailsPlugin, contact, acqueonContactNumber } = useSmartagent();
    const isOutboundContact = contact?.attributes?.['sa-pci-outbound'] === 'true'
    const [customerDetails, setCustomerDetails] = useState<IMulesoftCustomer | undefined>(undefined);
    const [userDetails, setUserDetails] = useState<ICustomersDetails>(blankCustomerDetails);
    const [SMSRecipient, setSMSRecipient] = useState("")
    const [ contactPhoneNumber, setContactPhoneNumber ] = useState('')
    
    const customerDataProm = useMemo(async () => {
        //  only fetch the customer details for Residential payments
        if (!accountId || userDetails.type === 'Commercial' ) {
            return undefined;
        }

        try {
            const response = await API.getMulesoftCustomer(accountId);
            return response.data;
        } catch (error) {
            return undefined;
        }
    }, [accountId, userDetails.type])

    useEffect(() => {
        customerDataProm.then(data => {
            if (data) {
                updateContractDetailsPlugin({
                    personDetails: data.customers[0]?.personDetails,
                    account: data.customers[0]?.accounts?.[0]
                })
                updateBillingPlugin(data?.customers[0]?.balance)
            }
            setCustomerDetails(data);
        });

    }, [updateContractDetailsPlugin, updateBillingPlugin, customerDataProm]);

  
      useEffect(()=> {
        // on an inbound call that has passed Accentures ID&V the customer account number will already be populated so this can be pulled out and the form populated when the call is first connected to the agent
        const contactAccountNumber = contact?.attributes?.AccountNumber ?? "";

     

        if(contactAccountNumber && contactAccountNumber !== '-') {
            updateAccountId(contactAccountNumber)
            setUserDetails(userDetails => ({...userDetails, accountId: contactAccountNumber}))
        }

    },[contact, updateAccountId])

    useEffect(()=> {
        // auto populate the phone number field if a customer is on a valid UK mobile number
        if(!paymentStatus?.SENT_TO_SPS) return

        const currentContactNumber = acqueonContactNumber || contact?.customerEndpointAddress
        const isValidUkMobile = currentContactNumber && isValidUkMobileNumber(currentContactNumber) 
        const contactE164Number = currentContactNumber &&  isValidUkMobile && formatToE164Number(currentContactNumber)

        if(isValidUkMobile && contactE164Number) {
            setContactPhoneNumber(contactE164Number)
        }

    },[paymentStatus?.SENT_TO_SPS, contact])

    
    const updateBillingCustomerData = useCallback(async () => {
        const customerData = (await API.getMulesoftCustomer(accountId)).data
        updateBillingPlugin(customerData?.customers[0]?.balance)
    }, [accountId, updateBillingPlugin])

    useEffect(() => {
        //  fetch the data again from Mulesoft if the payment is complete
        if(!isOutboundContact && paymentStatus?.COMPLETE && accountId && accountId !== '' && mulesoftTicketId) {
            updateBillingCustomerData()
        }

    }, [paymentStatus?.COMPLETE, accountId, mulesoftTicketId, updateBillingCustomerData, isOutboundContact])

    useEffect(() => {
        if(!isOutboundContact && paymentStatus?.COMPLETE){
            // call the customer details API again after 10 seconds if the mulesoft ticket ID is not created. ie the connect lambda times out
            setTimeout(()=>{
            if(!mulesoftTicketId){
                updateBillingCustomerData()
            }
          },10*1000)
        }
    },[paymentStatus?.COMPLETE, isOutboundContact])


    return {customerDetails, setCustomerDetails, setUserDetails, userDetails, setSMSRecipient, SMSRecipient, contactPhoneNumber, setContactPhoneNumber};
}