import style from './payment-status.module.css';
import React, { useEffect, useMemo } from 'react';
import { Button, Input } from '@missionlabs/smartagent-app-components';
import ProgressBox, { ProgressBoxStatus } from '../../components/ProgressBox';
import reset from '../../icons/reset.svg';
import { useTimer } from 'hooks/useTimer';
import useInterval from 'hooks/useInterval';
import { useAppContext } from 'hooks/useAppContext';
import { IPaymentStatus } from 'types';
import useSaveCardDetails from './useSaveCardDetails';
import useSendSMS from './useSendSMS';
import { ClipLoader } from 'react-spinners';
import classNames from 'classnames'
import { useSmartagent } from '../../hooks/useSmartagent';

type StatusDependencies = Record<keyof IPaymentStatus, (keyof IPaymentStatus)[]>
const statusDependencies: StatusDependencies = {
    CARD_NUMBER_ENTERED: [],
    CARD_NUMBER_ERROR: [],
    CARD_CVV_ERROR: [],
    CARD_EXPIRY_ENTERED: ["CARD_NUMBER_ENTERED"],
    CARD_EXPIRY_ERROR: [],
    CARD_CVV_ENTERED: ["CARD_NUMBER_ENTERED", "CARD_EXPIRY_ENTERED"],
    SENT_TO_SPS: ["CARD_NUMBER_ENTERED", "CARD_EXPIRY_ENTERED", "CARD_CVV_ENTERED"],
    COMPLETE: [],
    ERROR: [],
    INITIALIZED: [],
    READY: [],
}

const PaymentStatus: React.FC<any> = (props) => {

    const { paymentStatus, updatePaymentStatus, resetPaymentStatus, resetPaymentStatusAll, WPToken, refusalReason, mulesoftTicketId, updateAccountId, getMulesoftTicket, resetPluginsAll, userDetails, setSMSRecipient, contactPhoneNumber, setContactPhoneNumber, useDTMF  } = useAppContext();
    const { saveCardLoading, saveCardDetails, saveCardError, saveCardDone, saveCardExistingToken} = useSaveCardDetails()
    const { sendSMS, sendSMSError, sendSMSDone, sendSMSLoading, phoneNumberError, setPhoneNumberError, validatePhoneNumber } = useSendSMS()
    const { contact, acqueonContactNumber } = useSmartagent()
    const timeElapsed = useTimer(0);
    const isCommercialAccountType = userDetails.type === 'Commercial'

    useInterval(() => updatePaymentStatus(), 2000);

    useInterval(()=> {
        getMulesoftTicket ()
    },  paymentStatus?.COMPLETE ? 2000 : null)

    useEffect(() => {
        if (acqueonContactNumber) {
            setContactPhoneNumber(acqueonContactNumber)
        } else if (contact?.customerEndpointAddress) {
            setContactPhoneNumber(contact.customerEndpointAddress)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    type Statuses = Record<keyof IPaymentStatus, ProgressBoxStatus>;
    const statuses = useMemo<Statuses>(() => {
        const getStatus = (status: keyof IPaymentStatus): ProgressBoxStatus => {
            if (!paymentStatus) return "default";
            if (paymentStatus.COMPLETE || paymentStatus[status]) return "success";

            const dependenciesSatisfied = statusDependencies[status].reduce((satisfied, status) => {
                return !satisfied ? false : !!paymentStatus[status];
            }, true);

            if (dependenciesSatisfied) {
                if (paymentStatus.ERROR) return "failure";
                if (status === "CARD_NUMBER_ENTERED" && paymentStatus.CARD_NUMBER_ERROR) return "failure";
                if (status === "CARD_EXPIRY_ENTERED" && paymentStatus.CARD_EXPIRY_ERROR) return "failure"
                if (status === "CARD_CVV_ENTERED" && paymentStatus.CARD_CVV_ERROR) return "failure"
                return "in_progress"
            }

            return "default"
        };

        return {
            CARD_NUMBER_ENTERED: getStatus("CARD_NUMBER_ENTERED"),
            CARD_EXPIRY_ENTERED: getStatus("CARD_EXPIRY_ENTERED"),
            CARD_CVV_ENTERED: getStatus("CARD_CVV_ENTERED"),
            SENT_TO_SPS: getStatus("SENT_TO_SPS"),
            COMPLETE: "default",
            ERROR: "default",
            INITIALIZED: "default",
            CARD_EXPIRY_ERROR: "default",
            CARD_NUMBER_ERROR: "default",
            CARD_CVV_ERROR: "default",
            READY: "default"
        }

    }, [paymentStatus])

    const showAfterPaymentDetails = () => {
      
        if (paymentStatus?.SENT_TO_SPS || paymentStatus?.ERROR ) {
            return true
        } else {
            return false
        }
    }

    const setStyle = () => {
        if ( paymentStatus?.ERROR) {
            return style.error
        } 
        else if(paymentStatus?.SENT_TO_SPS  && !paymentStatus?.ERROR) {
            return  style.success
        } else 
            return ''
    }

    return (
        <div className={style.wrapper}>
            <div className={style.row1}>
                <h2 className={style.header}>Capturing card details</h2>
                <Button
                    aria-label="reset payment"
                    type="button"
                    onClick={()=> {
                        resetPaymentStatusAll()
                        updateAccountId('')
                        resetPluginsAll()
                        setSMSRecipient('')
                        setContactPhoneNumber('')
                    }}
                    className={style.resetButton}
                >
                    <span>Reset</span>
                    <img src={reset} alt="Reset" />
                </Button>
            </div>
            <div className={style.row2}>
                <ProgressBox
                    title="16 digit card number"
                    status={statuses["CARD_NUMBER_ENTERED"]}
                    reset={useDTMF? () => resetPaymentStatus("CARD_NUMBER_ERROR", false): undefined}
                />
                <ProgressBox

                    title="Expiry date"
                    status={statuses["CARD_EXPIRY_ENTERED"]}
                    reset={useDTMF? () => resetPaymentStatus("CARD_EXPIRY_ERROR", false): undefined}
                />
                <ProgressBox
                    title="CV2/CVV"
                    status={statuses['CARD_CVV_ENTERED']}
                    reset={useDTMF? () => resetPaymentStatus("CARD_CVV_ERROR", false): undefined}
                />
                <ProgressBox
                    title="Submitted to provider"
                    status={statuses['SENT_TO_SPS']}
                />
            </div>
            <div className={style.row3}>
                <div className={style['status-details']}>
                    <div className={`${style.timer} ${setStyle()}`}>
                        <label htmlFor="timer">Time Elapsed</label>
                        <time id="timer">
                            {timeElapsed}
                        </time>
                    </div>
                    {showAfterPaymentDetails() && 
                        <div className={style['status-details-item']} >
                            <div className={style['status-details-title']}>Payment Reference Number</div>
                            <div className={setStyle()}> {refusalReason ? refusalReason : mulesoftTicketId ? mulesoftTicketId : ''}</div>
                        </div>
                    }

                    {showAfterPaymentDetails() && 
                        <div className={style['status-details-item']}>
                            <div className={style['status-details-title']} >Payment Status</div>
                            <div className={setStyle()}>{paymentStatus?.ERROR ? 'Refused' : 'Success' }</div>
                        </div >
                    }
               
                </div>
          
            </div>
            <div className={style.row4}>
                <div className={style.smsCol} >
                        {(paymentStatus?.SENT_TO_SPS && !paymentStatus?.ERROR) && !isCommercialAccountType && 
                         <>
                            <div className={style.phoneNumber}>
                                <Input
                                    label="Phone Number"
                                    value={contactPhoneNumber}
                                    onChange={(customerPhoneNumber: any) => {   
                                        setContactPhoneNumber(customerPhoneNumber)
                                        if(phoneNumberError) setPhoneNumberError('')
                                        }
                                    }
                                    onBlur={() => validatePhoneNumber(contactPhoneNumber)}
                                    />
                                    <div className={`${style['input-error']} ${style['phone-input-error']}`}> { 
                                        (phoneNumberError !== '' || sendSMSError) && <>{phoneNumberError ? phoneNumberError :  sendSMSError}</>
                                            
                                        }
                                    </div>
                            </div>
                        </>
                     }  
                </div>
                <div className={style.buttonsCol}>
                    <div className={style.buttonsWrapper}>
                        <div>
                            {(paymentStatus?.SENT_TO_SPS && !paymentStatus?.ERROR && !isCommercialAccountType) && 
                                <Button
                                        className={classNames({
                                            [style.sendSMSButton]: true,
                                            [style.sendSMSError ]: sendSMSError
                                        })}
                                        type="button"
                                        disabled={!paymentStatus?.SENT_TO_SPS || sendSMSLoading || sendSMSDone }
                                        onClick={sendSMS}
                                        >
                                    {sendSMSLoading? <ClipLoader size={24} color="#fff" /> : 'Send SMS'}
                                </Button>
                            }
                        </div>
                        <div>
                           { !isCommercialAccountType && (
                                <Button
                                    className={classNames({
                                        [style.saveTokenButton]: true,
                                        [style.saveTokenError]: saveCardError,
                                        [style.saveCardExistingToken]: saveCardExistingToken,
                                    })}
                                    type="button"
                                    disabled={!paymentStatus?.SENT_TO_SPS || saveCardLoading || (saveCardDone && !saveCardExistingToken) || !props.tokenization || !WPToken}
                                    onClick={saveCardDetails}
                                    >
                                    {saveCardLoading ? <ClipLoader size={24} color="#fff" /> : 'Save Card Details'}
                                </Button>
                           )
                            }
                       
                            
                            <Button
                                className={style.confirmButton}
                                type="button"
                                disabled={!paymentStatus?.COMPLETE}
                                onClick={()=> {
                                    resetPaymentStatusAll()
                                    updateAccountId('')
                                    setSMSRecipient('')
                                    setContactPhoneNumber('')
                                }}
                            >
                                Confirm Completion
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentStatus;
