import { useCallback, useEffect, useState } from "react"
import { MulesoftToken } from 'types';
import * as API from 'services/api';
import { useAppContext } from "hooks/useAppContext";

export const useMulesoftTokens = () => {
    const [tokens, setTokens] = useState<MulesoftToken[]>([]);
    const [loading, setLoading] = useState(false);

    const { accountId } = useAppContext();

    useEffect(() => {
        if (!accountId) return setTokens([]);
        setLoading(true);  
        API.getMulesoftTokens(accountId)
            .then(res => {
                setTokens(res.data);
                setLoading(false);
            })
            .catch(() => setTokens([]))

    }, [accountId]);

    const deleteMulesoftToken = useCallback(async (tokenId: string, customerId: string) => {
        try {
            const deleted = await API.deleteMulesoftToken(tokenId, customerId);
            setTokens(old => old.filter(({ token }) => token.id !== deleted.data));
        } catch (error) {
            console.log(error)
        }
    }, [])

    return { tokens, loading, deleteMulesoftToken };
}