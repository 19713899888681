import { useEffect, useRef } from 'react';

export default function useInterval(callback: () => void, delay: number | null, onStart?: boolean) {
    const savedCallback = useRef<any>(null);

    // Remember the latest callback.

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (onStart) {
            tick();
        }
        if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }

    }, [delay, onStart]);
}
