import { Dropdown } from '@missionlabs/smartagent-app-components';
import { SADropdownOption, SADropdownProps } from '@missionlabs/smartagent-app-components/dist/Dropdown';
import React from 'react';
import { ICustomersDetails } from 'types';

interface Props extends Omit<SADropdownProps, "onChange" | "options"> {
    onChange(type: ICustomersDetails["type"]): void
}

type Option = SADropdownOption<ICustomersDetails["type"] | undefined>

const options: Option[] = [
    { label: "Residential", data: "Residential" },
    { label: "Commercial", data: "Commercial" }
]

const TypeDropdown: React.FC<Props> = (props) => {

    return (
        <Dropdown
            {...props}
            onChange={(option: Option) => {
                props.onChange(option.data!)
            }}
            onOpen={props.onOpen}
            onClose={props.onClose}
            options={options}
        />
    )
}

export default TypeDropdown;