import axios, { AxiosPromise } from "axios";
import { ICustomersDetails, IMulesoftCustomer, IPaymentStatus, MulesoftToken, WorldpayAutorizationId, TokenResponse } from "types";

const urls: { [key: string]: string } = {
    dev: 'https://h1tf32regk.execute-api.eu-west-2.amazonaws.com/dev',
    uat: 'https://dcclozva92.execute-api.eu-west-2.amazonaws.com/uat',
    prod: 'https://n820ox0348.execute-api.eu-west-2.amazonaws.com/prod'
};

const env = process.env.REACT_APP_ENV || 'dev';

const baseURL = process.env.REACT_APP_API || urls[env]

interface IMakeRequest {
    url: string;
    base?: string;
    data?: any;
    headers?: any;
    method?: 'get' | 'put' | 'post' | 'delete' | 'patch';
    params?: any;
    cancelToken?: any;
    timeout?: number
}

interface ISendSMSBody {
    phoneNumber: string
    customerName: string
    mulesoftTicketId?: number | null
    customerId?: string;

}
interface ISendSMSResponse {
    message?: string
}

function makeRequest<T extends any>({ url, method = 'get', data, headers, params, cancelToken, timeout }: IMakeRequest) {
    return axios({
        baseURL,
        url,
        method,
        data,
        headers,
        params,
        cancelToken,
        timeout
    }) as AxiosPromise<T>;
}

interface IGetPaymentStatusResponse {
    Status: IPaymentStatus;
    refusalReason: any;
    MulesoftTicketId: number | undefined | null;
    authorisationId?: WorldpayAutorizationId;
    worldPayToken: {
        id?: string
    };
}
export const getPaymentStatus = async (contactId: string) => {
    const response = await makeRequest<IGetPaymentStatusResponse>({
        url: `/contacts/${contactId}/payment/status`
    });

    return response;
};

export const startPayment = async (contactId: string, body: ICustomersDetails) => {
    const data = {
        paymentId: contactId,
        paymentProvider: 'worldpay',
        ...body
    }
    const response = await makeRequest<IGetPaymentStatusResponse>({
        url: `/contacts/${contactId}/payment`,
        method: "post",
        data
    });

    return response;
}

export const resetPaymentStatus = async (paymentId: string) => {
    const response = await makeRequest<string>({
        url: `payments/${paymentId}/reset`,
        method: 'post'
    });

    return response
}

export const getMulesoftTokens = async (customerId: string) => {
    const response = await makeRequest<MulesoftToken[]>({
        url: `/customers/${customerId}/tokens`
    });

    return response;
}

export const deleteMulesoftToken = async (tokenId: string, customerId: string) => {
    const response = await makeRequest<string>({
        url: `/tokens/${tokenId}?customerId=${customerId}`,
        method: 'delete'
    });

    return response;
}

export const getMulesoftCustomer = async (accountId: string) => {
    const response = await makeRequest<IMulesoftCustomer>({
        url: `/accounts/${accountId}/details`
    });
    return response;
}

export const putPaymentStatus = async (contactId: string, type: keyof IPaymentStatus, value: boolean) => {
    const response = await makeRequest<IPaymentStatus>({
        url: `/contacts/${contactId}/payment/status`,
        data: {
            type,
            value
        },
        method: "put"
    })

    return response;
}

export const putMulesoftToken = async (contactId: string, customerId: string) => {
    const response = await makeRequest<TokenResponse>({
        url: `/customers/${customerId}/tokens`,
        data: {
            contactId
        },
        method: "put"
    });

    return response;
}



export const getMulesoftTicket = async (contactId: string) => {
    const response = await makeRequest<IGetPaymentStatusResponse>({
        url: `/contacts/${contactId}/payment/mulesoft-data`
    });

    return response?.data;
};

export const sendSMSNotification = async (contactId: string, body: ISendSMSBody) => {

    const response = await makeRequest<ISendSMSResponse>({
        url: `/customers/${contactId}/sms-notification`,
        data: body,
        method: "put",
        timeout: 5000
    });

    return response;
}
