import style from './progress-box.module.css';
import React from 'react';
import IconTick from './icon-tick-green.png';
import { Button } from '@missionlabs/smartagent-app-components';
import RefreshIcon from './icon-reset-white.svg';

export type ProgressBoxStatus = "default" | "in_progress" | "success" | "failure";

interface Props {
    title: string;
    status: ProgressBoxStatus;
    reset?():void
};

const wrapperClassNames: Record<ProgressBoxStatus, string> = {
    default: style.wrapperDefault,
    in_progress: style.wrapperInProgress,
    success: style.wrapperSuccess,
    failure: style.wrapperFailure
}

const statuses: Record<ProgressBoxStatus, string> = {
    default: "Not Completed",
    in_progress: "In progress",
    success: "Completed",
    failure: "Error"
}

const ProgressBox: React.FC<Props> = ({ title, status, reset }) => {
    return (
        <div className={wrapperClassNames[status]}>
            <div className={style.detailsWrapper}>
                <div className={style.titleWrapper}>
                    {status === "failure" && !!reset && (
                        <Button className={style.resetButton} type="button" styling="blank" onClick={reset}>
                            <img src={RefreshIcon} alt="refresh" />
                        </Button>
                    )}
                    <h3 className={style.title}>{title}</h3>
                </div>
                <p className={style.status}>{statuses[status]}</p>
            </div>
            <div className={style.checkbox}>
                {status === "success" && (
                    <img src={IconTick} alt="Success" />
                )}
            </div>
        </div>
    );
};

export default ProgressBox;
