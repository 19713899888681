import style from './customer-details.module.css';
import { Button, Form, Input, Radio } from '@missionlabs/smartagent-app-components';
import React, { useCallback, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { ICustomersDetails } from 'types';
import { useAppContext } from 'hooks/useAppContext';
import TokenListDropdown from 'components/TokenList';
import TypeDropdown from 'components/TypeDropdown';
import { getFormattedDate } from '../../utils'
import { useSmartagent } from 'hooks/useSmartagent';

export const blankCustomerDetails: ICustomersDetails = {
    type: "Residential",
    accountId: '',
    customerId: '',
    customerReference: '',
    paymentAmount: '',
    customerName: '',
    tokenId: undefined,
}


interface IErrorMessages {
    paymentAmount: string;
    accountId: string;
    customerName: string;
}

const blankErrorMessages: IErrorMessages = {
    paymentAmount: '',
    accountId: '',
    customerName: ''
}

interface Props {
    tokenization: boolean,
    setTokenization: any
}

const CustomerDetails: React.FC<Props> = (props) => {
    const { startPayment, updateAccountId, customerDetails, useDTMF, setUseDTMF,  userDetails, setUserDetails, setSMSRecipient } = useAppContext();
    const [errorMessages, setErrorMessages] = useState<IErrorMessages>(blankErrorMessages)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const { billAccountNumber } = useSmartagent();

    useEffect(()=> {
        //  create the customer ref when the data is returned from the api and add to the current state
        if(userDetails?.accountId ===  '') return
        if(userDetails.type === 'Commercial') return

        const formattedDate = getFormattedDate()
        let customerRef = userDetails?.accountId + formattedDate

            setUserDetails(details => ({
                ...details,
                customerReference: customerRef
            }))
            
        },[userDetails.accountId])
        
        useEffect(()=> {
            setUserDetails(details => ({
                ...details,
               paymentAmount : ''
            }))
            setErrorMessages(blankErrorMessages)
            if(userDetails.type === "Commercial") {
                setUserDetails(details => ({
                    ...details,
                   tokenId : undefined
                }))
            }
        },[userDetails.type])
    
    useEffect(() => {
        if(billAccountNumber && billAccountNumber.length) {
            setUserDetails(details => ({
                ...details,
               accountId : billAccountNumber
            }));
            updateAccountId(billAccountNumber);
        } else {
            setUserDetails(blankCustomerDetails);
            updateAccountId(blankCustomerDetails.accountId);
        }
    }, [billAccountNumber])

    const fixDecimalPlaces = (amount?: string) => {
        if (!amount) return '0';
        let result
        
        const [left, right] = amount.split('.');
        result = left + '.' + right.padEnd(2, '0');
        return result
        
    }
    const validateForm = useCallback((value: string, type?: string) => {
        // /xA3 represents £
        console.log(type)
        const regex = /^\xA3?(?=\(.*\)|[^()]*$)\(?\d{1,3}(,?\d{3})?(\.\d\d)?\)?$/
        const invalidCurrencyFormat = !regex.test(value) || Number(value.slice(1))  === 0 || Number(value.replace(/[^0-9.-]+/g,"")) < 1
        const invalidAmount = (type === 'Commercial' && Number(value.replace(/[^0-9.-]+/g,"")) > 10000) || (type === 'Residential' && Number(value.replace(/[^0-9.-]+/g,"")) > 3000)
        if (value.charAt(0) !== '£') {
        setFormIsValid(false)
            return 'Value must be in £ format'
        }
        else if (invalidAmount) {
            const maxPayment = type === "Commercial" ? "£10,000" : "£3000"
            const errorMessage = `${type} payments can not exceed ${maxPayment}`
            setFormIsValid(false)
            setErrorMessages(errorMessages => ({...errorMessages, paymentAmount: errorMessage}));
        }
        else if (invalidCurrencyFormat) {
            setFormIsValid(false)
            setErrorMessages(errorMessages => ({...errorMessages, paymentAmount: 'Please enter a valid Payment amount'}));
            return 'Invalid currency format'
        }
        else {
            setFormIsValid(true)
            setErrorMessages(errorMessages => ({...errorMessages, paymentAmount: ''}));
            return ''
        }
    }
    ,[])

    const validateAccountId = useCallback((value: string) => {
        const regex = /^[a-zA-Z0-9 ]*$/
        const invalidAccountId = !regex.test(value) 
       
        if (invalidAccountId) {
            setFormIsValid(false)
            setErrorMessages(errorMessages => ({...errorMessages, accountId: 'Account Number has invalid characters.'}));
        }
    }
    ,[])

    useEffect(() => {
        if (!customerDetails && !userDetails.accountId) {
            setErrorMessages(blankErrorMessages)
            return setUserDetails(blankCustomerDetails);

        }
        if(userDetails.accountId) {
            setErrorMessages(blankErrorMessages)
            const customer = customerDetails?.customers?.[0];
            const person = customer?.personDetails;
            const name = `${person?.title ?? ''} ${person?.firstName ?? ''} ${person?.lastName?? ''}`.trim();
            const hasMustPayAmmount = customer?.balance.mustPayAmount && parseInt(customer?.balance.mustPayAmount) > 0
            const formattedNumber = hasMustPayAmmount && userDetails.type != 'Commercial' ? '£' + fixDecimalPlaces(customer?.balance?.mustPayAmount)?.padEnd(4, "0").padStart(4, "0") ?? '' : '';

            if(!customer) {
                setUserDetails(details => ({
                    ...details,
                    customerName: '',
                    paymentAmount: '',
                    customerId: '',
                }))
                setErrorMessages(errorMessages => ({...errorMessages, paymentAmount: 'Invalid Payment Amount - populate Account Number first'}));
            }
            if(!hasMustPayAmmount && customer) {
                setErrorMessages(errorMessages => ({...errorMessages, paymentAmount: 'Invalid payment amount'}));
            }

            setUserDetails(details => ({
                ...details,
                customerName: name || details.customerName,
                paymentAmount: formattedNumber,
                customerId: customer?.id ?? '',
            }))

        validateForm(formattedNumber)
    }

    }, [customerDetails, validateForm])

    useEffect(()=>{
        if(!userDetails.accountId){   
            setErrorMessages((errorMessages)=>({...errorMessages,paymentAmount:''}))
        }        
    },[userDetails.accountId])

    const [formIsValid, setFormIsValid] = useState(false)

    useEffect(()=> {
        if(formIsValid) setErrorMessages(blankErrorMessages)
    }, [formIsValid, userDetails])


    const processPayment = useCallback(() => {
        if(userDetails.type === "Commercial") {
            props.setTokenization(false)
        }
        else if(userDetails.type === "Residential") {
            props.setTokenization(true)
        }
        if(userDetails.paymentAmount.charAt(0) === "£") {
            userDetails.paymentAmount = userDetails.paymentAmount.substring(1)
        }
        validateForm(userDetails.paymentAmount)
        // if (!contact?.ID) {
        //     return console.error('no contact id');
        // }
        if(formIsValid) {
            startPayment(userDetails, useDTMF)
            setSMSRecipient(userDetails?.customerName )
        } 
    }, [userDetails, useDTMF, startPayment, formIsValid, validateForm, setSMSRecipient, setUserDetails]);

    return (
        <Form onSubmit={processPayment} className={style.wrapper}>
            <div className={style.row1}>
                <TypeDropdown
                    onChange={type => setUserDetails({ ...userDetails, type })}
                    onOpen={() => setDropdownOpen(true)}
                    onClose={() => setDropdownOpen(false)}
                    value={userDetails.type}
                    label="Type"
                />
                <span>
                    <Input
                        label="Account number"
                        value={userDetails.accountId}
                        onChange={(accountId: any) => {
                            setUserDetails({ ...userDetails, accountId: userDetails.type === "Commercial" ? accountId : accountId.trim() })
                            }
                        }
                        required
                        onBlur={() => {
                            updateAccountId(userDetails.accountId)
                            validateAccountId(userDetails.accountId)
                            }
                        }
                        />
                    {errorMessages.accountId !== '' && <span className="input-error">{errorMessages.accountId}</span>}
                </span>
                <Input
                    label={userDetails.type === "Residential" ? "Customer reference" : "Invoice Reference"}
                    value={userDetails.customerReference}
                    onChange={(customerReference: any) => setUserDetails({ ...userDetails, customerReference })}
                />
                <Input
                    label="Cardholder Name"
                    value={userDetails.customerName}
                    onChange={(customerName: any) => setUserDetails({ ...userDetails, customerName })}
                />
            </div>
            <div className={style.row2}>
                <span>
                    <Input
                        placeholder={"£"}
                        label="Payment Amount"
                        value={userDetails.paymentAmount}
                        required
                        onChange={(paymentAmount: any) => {
                            if(paymentAmount.charAt(0) !== "£") {
                                paymentAmount = `£${paymentAmount}`
                            }
                            setErrorMessages(blankErrorMessages)
                            setUserDetails({ ...userDetails, paymentAmount })
                        }}
                        onBlur={() => {
                            let paymentAmount = userDetails.paymentAmount;
                            if (!paymentAmount) return;
                            if (!paymentAmount.includes('.')) {
                                paymentAmount = `${paymentAmount}.00`;
                            }
                            paymentAmount = fixDecimalPlaces(paymentAmount) 
                            setUserDetails({ ...userDetails, paymentAmount })
                            validateForm(paymentAmount, userDetails.type)
                        }}
                    />
                    {errorMessages.paymentAmount !== '' && <span className="input-error">{errorMessages.paymentAmount}</span>}
                </span>
                <TokenListDropdown
                    disabled={userDetails.type === "Commercial"}
                    onOpen={() => setDropdownOpen(true)}
                    onClose={() => setDropdownOpen(false)}
                    label="Card"
                    className={style.cardsDropdown}
                    value={userDetails.tokenId}
                    onChange={(tokenId) => setUserDetails(userDetails => ({ ...userDetails, tokenId }))}
                />
            </div>
            <div className={style.row3}>
                <div className={style.radiosWrapper}>
                    <label>Collection method</label>
                    <div className={style.radios}>
                        <Radio
                            name="Collect via keypad input"
                            value="keypad"
                            checked={useDTMF}
                            label="Collect via keypad input"
                            onChange={() => setUseDTMF(true)}
                        />
                        <Radio
                            name="Collect via voice input"
                            value="voice"
                            checked={!useDTMF}
                            label="Collect via voice input"
                            onChange={() => setUseDTMF(false)}
                        />
                    </div>
                </div>
                <Button
                    type="button"
                    onClick={processPayment}
                    large
                    className={style.button}
                    disabled={(!userDetails.accountId
                        || (!userDetails.customerId && userDetails.type === "Residential")
                        || !userDetails.customerName
                        || !userDetails.paymentAmount 
                        || !userDetails.customerReference
                        || !formIsValid
                        || dropdownOpen
                    )}
                >
                    {false ? (
                        <ClipLoader size={20} color={'#9FABB8'} />
                    ) : (
                        'Begin payment collection'
                    )}
                </Button>
            </div>
        </Form>
    )
}

export default CustomerDetails;
