import React, { createContext, useState } from 'react';
import { IPaymentStatus, ICustomersDetails, IMulesoftCustomer, RefusalReason, MulesoftTicketId, WorldpayAutorizationId } from '../../types';
import { useCustomerDetails } from './useCustomerDetails';
import { usePaymentStatus } from './usePaymentStatus';


type PaymentStatusState = IPaymentStatus | undefined;
interface IAppContext {
    paymentStatus: PaymentStatusState;
    refusalReason: RefusalReason;
    mulesoftTicketId: MulesoftTicketId;
    worldpayAuthorizationId: WorldpayAutorizationId;
    accountId: string;
    customerDetails: IMulesoftCustomer | undefined;
    WPToken:string;
    startPayment(body: ICustomersDetails, useDTMF: boolean):void;
    updatePaymentStatus():void;
    resetPaymentStatusAll():void;
    resetPaymentStatus(status: keyof IPaymentStatus, value: boolean):void;
    updateAccountId(accountId: string):void;
    useDTMF: boolean;
    setUseDTMF: React.Dispatch<React.SetStateAction<boolean>>
    pciStatusLoading: boolean;
    setPciStatusLoading: React.Dispatch<React.SetStateAction<boolean>>
    getMulesoftTicket (): void
    resetPluginsAll():void
    userDetails: ICustomersDetails
    setUserDetails: React.Dispatch<React.SetStateAction<ICustomersDetails>>
    SMSRecipient: string
    setSMSRecipient: React.Dispatch<React.SetStateAction<string>> 
    contactPhoneNumber: string
    setContactPhoneNumber: React.Dispatch<React.SetStateAction<string>> 
  
}

export const AppContext = createContext<IAppContext>({} as any);

export const AppProvider : React.FC = ({ children }) => {

    const [accountId, updateAccountId] = useState("");

    const { paymentStatus, refusalReason, mulesoftTicketId, worldpayAuthorizationId, startPayment, updatePaymentStatus, resetPaymentStatusAll, resetPaymentStatus, useDTMF, setUseDTMF, setPciStatusLoading, pciStatusLoading, getMulesoftTicket, resetPluginsAll, WPToken  } = usePaymentStatus();

    const { customerDetails, userDetails, setUserDetails, SMSRecipient, setSMSRecipient, contactPhoneNumber, setContactPhoneNumber } = useCustomerDetails(accountId, paymentStatus, updateAccountId, mulesoftTicketId);

    return (
        <AppContext.Provider
            value={{
                paymentStatus,
                refusalReason,
                worldpayAuthorizationId,
                mulesoftTicketId,
                accountId,
                customerDetails,
                WPToken,
                startPayment,
                updatePaymentStatus,
                resetPaymentStatus,
                resetPaymentStatusAll,
                updateAccountId,
                useDTMF,
                setUseDTMF,
                pciStatusLoading,
                setPciStatusLoading,
                getMulesoftTicket,
                resetPluginsAll,
                userDetails,
                setUserDetails,
                SMSRecipient,
                setSMSRecipient,
                contactPhoneNumber,
                setContactPhoneNumber
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
