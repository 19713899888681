import { useAppContext } from "hooks/useAppContext";
import { useSmartagent } from "hooks/useSmartagent";
import { useCallback, useMemo, useState } from "react";
import * as API from 'services/api';

const useSaveCardDetails = () => {
    const [saveCardLoading, setSaveCardLoading] = useState(false);
    const [saveCardError, setSaveCardError] = useState("");
    const [saveCardExistingToken, setSaveCardExistingToken] = useState(false)
    const [saveCardDone, setSaveCardDone] = useState(false);
    const { contact, showPopupMessage } = useSmartagent();
    const { accountId } = useAppContext();

    const saveCardDetails = useCallback(async () => {
        setSaveCardLoading(true);
        setSaveCardError("")
        try {
            if (!accountId) return console.log('no customer ID');
            const res = await API.putMulesoftToken(contact.ID, accountId);
            console.log("Save Card Response", res)
            if(res.data.existingToken === "Y") {
                console.log('card already exists')
                setSaveCardExistingToken(true)
                showPopupMessage(
                    'ERROR',
                    'Payment details already on file - details could not be saved'
                    )
                }
                else{
                console.log('card saved')
                showPopupMessage(
                    'SUCCESS',
                    'Payment details successfully saved'
                )

            }
            setSaveCardDone(true);
        } catch (err) {
            setSaveCardError("Could not save card details")
        } finally {
            setSaveCardLoading(false);
        }
    }, [contact, accountId, showPopupMessage]);

    return { saveCardLoading, saveCardDone, saveCardError, saveCardDetails, saveCardExistingToken}
}

export default useSaveCardDetails;