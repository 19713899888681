import { useState } from 'react';
import useInterval from './useInterval';

export const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60).toString();
    const seconds = (time % 60).toString();
    return `${minutes.padStart(2, '0')}m ${seconds.padStart(2, '0')}s`
};

export const useTimer = (timerSeconds: number) => {
    const [seconds, setSeconds] = useState<number>(timerSeconds);
    useInterval(() => setSeconds(seconds + 1), 1000);
    return formatTime(seconds);
};
