import { Button, Dropdown } from '@missionlabs/smartagent-app-components';
import { SADropdownOption, SADropdownProps } from '@missionlabs/smartagent-app-components/dist/Dropdown';
import { useAppContext } from 'hooks/useAppContext';
import React, { useMemo } from 'react';
import { MulesoftToken } from 'types';
import style from './tokenDropdown.module.css';
import DeleteCross from './icon-delete-cross.svg';
import { useMulesoftTokens } from './useMulesoftTokens';
type Option = SADropdownOption<MulesoftToken | undefined>;

const formatDateToCardExpiry = (date: Date) => 
    new Intl.DateTimeFormat('en', {
        year: "2-digit",
        month: "2-digit"
    })
        .format(new Date(date))

const getLabel = (token: MulesoftToken) =>
    token && `XXXX-XXXX-XXXX-${token?.card.lastFourDigits} ${formatDateToCardExpiry(token.card.expiryDate)}`

interface Props extends Omit<SADropdownProps, "onChange" | "options"> {
    onChange(tokenId: string): void
}

const TokenListDropdown: React.FC<Props> = (props) => {
    const { customerDetails, accountId } = useAppContext();
    const { tokens, loading, deleteMulesoftToken } = useMulesoftTokens();
    
    const options = useMemo<Option[]>(() => {
        const options = tokens.map<Option>(token => ({
            key: token.token.id,
            label: getLabel(token),
            data: token
        })).sort((token, nextToken)=> {
            const tokenlastUsedDate  =  new Date(token.data?.token?.lastUsedDate ?? '')
            const nextTokenLastUsedDate  = new Date(nextToken?.data?.token.lastUsedDate ?? '')   

            return tokenlastUsedDate < nextTokenLastUsedDate ? 1 : -1
        })


        return [
            { key: "", label: "Select token...", data: undefined },
            ...options
        ]

    }, [tokens])

    return (
        <Dropdown
            {...props}
            onOpen={props.onOpen}
            onClose={props.onClose}
            disabled={!customerDetails || loading || props.disabled}
            options={options}
            value={
                props.value
                    ? getLabel(tokens.find(tk => tk.token.id === props.value)!)
                    : "Select token..."
            }
            onChange={option => {
                props.onChange(option.key!)
            }}
            renderOptionLabel={option => !option.key ? option.label : (
                <div className={style.option}>
                    <span>{option.label}</span>
                    <Button type="button" onClick={(ev) => {
                        ev.stopPropagation();
                        deleteMulesoftToken(option.key!, accountId!)
                    }}>
                        <img src={DeleteCross} alt="Delete"/>
                    </Button>
                </div>
            )}
        />
    )
}

export default TokenListDropdown;