import { useCallback, useState } from "react";
import { IPaymentStatus, RefusalReason, MulesoftTicketId, WorldpayAutorizationId } from 'types';
import { useSmartagent } from "hooks/useSmartagent";
import * as API from 'services/api';
import useInterval from 'hooks/useInterval';


export type PaymentStatusState = IPaymentStatus | undefined;

// const _paymentStatus = {
//     INITIALIZED: true,
//     CARD_CVV_ENTERED: false,
//     CARD_EXPIRY_ENTERED: false,
//     CARD_NUMBER_ENTERED: true,
//     COMPLETE: false,
//     ERROR: false,
//     CARD_EXPIRY_ERROR: true,
//     CARD_NUMBER_ERROR: true,
//     SENT_TO_SPS: false
// }


export const usePaymentStatus = () => {
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusState>(undefined);
    const [refusalReason, setRefusalReason] = useState<RefusalReason>(undefined)
    const [mulesoftTicketId, setMulesoftTicketId] = useState<MulesoftTicketId>(undefined);
    const [WPToken, setWPToken] = useState<string>("");
    const [worldpayAuthorizationId] = useState<WorldpayAutorizationId>(undefined)
    const [useDTMF, setUseDTMF] = useState<boolean>(true);
    const smartagent = useSmartagent();

    const [pciStatusLoading, setPciStatusLoading] = useState(false)
    const [attemptsToGetPCIStatus, setAttemptsToGetPCIStatus] = useState(0)


    const resetPaymentStatusAll = useCallback(() => {
        setPaymentStatus(undefined)
        setRefusalReason(undefined)
        setMulesoftTicketId(undefined)
        smartagent.endConferenceCall(useDTMF)
        setUseDTMF(true)

        if (smartagent.contact?.attributes?.['sa-pci-outbound'] === 'true') {
            smartagent.resetPlugins()
        }
    }, [smartagent, useDTMF]);

    const startPayment = useCallback(async (body, useDTMF) => {
        try {
            //  creates the initial payment table on the TW account
            await API.resetPaymentStatus(smartagent.contact.ID);
            await API.startPayment(smartagent.contact.ID, body);
            smartagent.transferToPayment(useDTMF);
            //  the new polling endpoint iniside PCI may take some time to be ready so set the loading state at first
            setPciStatusLoading(true)
  
        } catch (error) { 
            console.log(error) 
            setPciStatusLoading(false)
        }
    }, [smartagent]);

    const getInitialPaymentStatus = useCallback(async () => {
        //  try to get the initial payment status from the new PCI endpoint

        //  only try 3 times before resetting the payment plugin and ending the conference call. If the PCI polling endpoint has failed to respond that many times we can assume there has been an error and cancel so the agent can try again
        if(attemptsToGetPCIStatus > 9) {
            setPciStatusLoading(false)
            setAttemptsToGetPCIStatus(0)
            resetPaymentStatusAll()
            return
        }

        try {
            const response = await API.getPaymentStatus(smartagent.contact.ID);

        
            if(!response || !response.data.Status || response.status !== 200) {
                //  if the response does not bring back the payment status keep trying until the max attempts are reached
                setPciStatusLoading(true)
                setAttemptsToGetPCIStatus(prev => (prev +=1))
                return
            }
            // if the Status data is returned then stop the loading and update the state so the polling UI will render on screen
            if(response.data?.Status?.READY) {
                setPaymentStatus(response.data.Status);
                setPciStatusLoading(false)
                setAttemptsToGetPCIStatus(0)   
            }

        } catch (error) { console.log(error) }
    }, [smartagent, attemptsToGetPCIStatus, resetPaymentStatusAll])

    
   
    useInterval(()=> {
        getInitialPaymentStatus()
    },  pciStatusLoading ? 2000 : null)


    const updatePaymentStatus = useCallback(async () => {
        try {
            // try to get the data from the PCI endpoint if it is available
            const response = await API.getPaymentStatus(smartagent.contact.ID);
            const {Status, refusalReason } = response.data

            if(Status) {
                setPaymentStatus(Status)
            }

            if(refusalReason) {
                setRefusalReason(refusalReason)
            }

        } catch (error) { console.log(error) }
    }, [smartagent])
    
    const getMulesoftTicket = useCallback(async () => {
        try {
            // try to get the data from the PCI endpoint if it is available
            if(paymentStatus?.COMPLETE) {
                API.getMulesoftTicket(smartagent.contact.ID).then(response => {
                    setMulesoftTicketId(response.MulesoftTicketId);
                    setWPToken(response.worldPayToken?.id ?? "");
                }).catch(error => { 
                    console.log(error) 
                })
           }

        } catch (error) { console.log(error) }
    }, [paymentStatus?.COMPLETE, smartagent.contact.ID]);

    const resetPaymentStatus = useCallback(async (type: keyof IPaymentStatus, value: boolean) => {
        try {
            const response = await API.putPaymentStatus(smartagent.contact.ID, type, value);
            setPaymentStatus(response.data)
        } catch (error) { console.log(error) }

    }, [smartagent])

    const resetPluginsAll = useCallback(() => {
        smartagent.resetPlugins()
    }, [smartagent]
)

    return { paymentStatus, startPayment, WPToken, updatePaymentStatus, resetPaymentStatusAll, resetPaymentStatus, refusalReason, mulesoftTicketId, worldpayAuthorizationId, useDTMF, setUseDTMF, setPciStatusLoading, pciStatusLoading, getMulesoftTicket, resetPluginsAll}
};