import '../../node_modules/@missionlabs/smartagent-app-components/dist/index.css';
import React, { useEffect, useState } from 'react';
import CustomerDetails, { blankCustomerDetails } from './CustomerDetails/CustomerDetails';
import PaymentStatus from './PaymentStatus/PaymentStatus';
import LoadingComponent from './LoadingComponent/LoadingComponent';
import { useAppContext } from 'hooks/useAppContext';

const Main: React.FC = () => {
    const { paymentStatus, pciStatusLoading, setUserDetails } = useAppContext();
    const [ tokenization, setTokenization] = useState<boolean>(true)

    useEffect(() => {
        if (!paymentStatus?.INITIALIZED) {
            setUserDetails(blankCustomerDetails)
        }
    }, [paymentStatus?.INITIALIZED, setUserDetails])

    return (
        <div className="plugin">
            {pciStatusLoading ? <LoadingComponent loadingMessage="Connecting to the PCI Payment Session" /> : (
                    paymentStatus?.INITIALIZED
                    ? <PaymentStatus tokenization={tokenization} />
                    : <CustomerDetails tokenization={tokenization} setTokenization={setTokenization} />
                )
            }
        </div>
    );

};

export default Main;
