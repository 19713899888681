import React from 'react';
import { ClipLoader } from 'react-spinners';
import style from './loading-component.module.css';
import { useAppContext } from 'hooks/useAppContext';
import { Button } from '@missionlabs/smartagent-app-components';
import reset from '../../icons/reset.svg';

interface IProps {
  loadingMessage?: string;
}

const LoadingComponent: React.FC<IProps> = ({loadingMessage = 'Loading...'}) => {
  const { resetPaymentStatusAll, updateAccountId, resetPluginsAll, setSMSRecipient, setContactPhoneNumber, setPciStatusLoading } = useAppContext();
    return (
        <div className={style.wrapper} >
            <div className={style.row1}>
                <Button
                    aria-label="reset payment"
                    type="button"
                    onClick={()=> {
                        resetPaymentStatusAll()
                        updateAccountId('')
                        resetPluginsAll()
                        setSMSRecipient('')
                        setContactPhoneNumber('')
                        setPciStatusLoading(false)
                    }}
                    className={style.resetButton}
                >
                    <span>Reset</span>
                    <img src={reset} alt="Reset" />
                </Button>
          </div>
          <ClipLoader size={40} color={'#9FABB8'} />
          <div className={style.loadingMessage}>
            {loadingMessage}
          </div>
        </div>
    );
};

export default LoadingComponent;