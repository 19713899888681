import { AxiosError } from "axios";
import { useAppContext } from "hooks/useAppContext";
import { useSmartagent } from "hooks/useSmartagent";
import { useCallback, useMemo, useState } from "react";
import * as API from 'services/api';
import { isValidUkMobileNumber, formatToE164Number } from '../../utils'

const useSendSMS = () => {
    const [sendSMSLoading, setSendSMSLoading] = useState(false);
    const [sendSMSError, setSendSMSError] = useState("");
    const [sendSMSDone, setSendSMSDone] = useState(false);
    const { contact } = useSmartagent();
    const { customerDetails,  mulesoftTicketId, SMSRecipient, contactPhoneNumber } = useAppContext();

    const [phoneNumberError, setPhoneNumberError] = useState('')

    const customerId = useMemo(() => {
        return customerDetails?.customers?.[0]?.id;
    }, [customerDetails])

    const validatePhoneNumber = useCallback((number: string) => {
        if(!number) {
            return false
        }

        try {
            const isValidNumber = isValidUkMobileNumber(number)

            if(!isValidNumber) {
                setPhoneNumberError('The number is not a valid UK Mobile Number')
                setSendSMSLoading(false)
                return false
            }

            return true
        } catch(err) {
            console.log(err)
            return false
        }
       
    }, [])

    const sendSMS = useCallback(async () => {
        setSendSMSLoading(true);
        setSendSMSError("")
        setPhoneNumberError("")
        
        try {
            if(!contactPhoneNumber) {
                setPhoneNumberError("Phone number cannot be empty.")
            }
            const isValidNumber = validatePhoneNumber(contactPhoneNumber)

            if(!isValidNumber) {
               return
            }

            const formattedNumber = formatToE164Number(contactPhoneNumber)

            // if (!customerId) return console.log('no customer ID');


            const res = await API.sendSMSNotification(contact.ID, {
                phoneNumber: formattedNumber,
                customerName: SMSRecipient, 
                mulesoftTicketId,
                customerId: customerId                

            });

            // if the Lambda fails and returns an error message display that message in the plugin
            if(res.status !== 200 && typeof res?.data === 'string') {
                setSendSMSError(res?.data)
                return
            }
            setSendSMSDone(true);


        } catch (err) {
            const error = err as AxiosError
           
            // the mulesoft sms can take a long time so if the request takes longer than 5 seconds without returning an error just assume it was successful 
            if (error?.code === 'ECONNABORTED') {
                setSendSMSDone(true);
                return
            }
            
            console.log('Error sending the SMS : ', err)
            setSendSMSError("Error sending the SMS")

        } finally {
          setSendSMSLoading(false);
        }

    }, [contact,  mulesoftTicketId, SMSRecipient, contactPhoneNumber, validatePhoneNumber, customerId]);

    return { sendSMSLoading, sendSMSDone, sendSMSError,  sendSMS, phoneNumberError, setPhoneNumberError, validatePhoneNumber }
}

export default useSendSMS;